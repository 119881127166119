.mail-selector {
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
}

.mail-selector>*:not(:first-child) {
  margin-left: 0.5em !important;
}

.mail-preview>.subject {
  display: flex;
}

.mail-preview>.subject>span {
  font-weight: bold;
  margin-right: 0.5em;
}

.mail-preview>.subject>div {
  flex: 1;
  font-size: 1.25em !important;
  font-weight: 300;
  opacity: 0.75;
}

.mail-preview>.content {
  white-space: pre-wrap;
  font-size: 1.25em !important;
  font-weight: 300;
  opacity: 0.75;
}

.mail-field>label {
  font-weight: bold;
  font-size: 1.25em;
  user-select: none;
  pointer-events: none;
}

.mail-field>label::after {
  content: ":";
  margin-left: 0.1em;
  margin-right: 0.5em;
}

.mail-field>*:not(label) {
  white-space: pre-wrap;
  font-weight: 300;
  font-size: 1.25em;
  opacity: 0.75;
}

.mail-preview span.highlight {
  background-color: #fbbd08;
  padding: 0.25em;
  border-radius: 5px;
  cursor: help;
  font-size: 0.75em;
  font-family: monospace;
  vertical-align: bottom;
  border: 1px dashed;
}

.mail-preview span.highlight.unknown {
  background-color: #c9c9c9;
}

.sidebar-container {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% - 50px) !important;
}

.sidebar {
  background-color: white;
}

.sidebar>#header  {
  padding: 0.75em;
  font-size: 1em;
  text-align: right;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}

.sidebar>#header>.version {
  font-weight: bolder;
  cursor: help;
}

.sidebar>#header>.version:hover {
  text-decoration: underline;
}

.sidebar>#items {
  margin-top: 1em;
}

.sidebar-container > .pusher {
  height: 100%;
}

.sidebar .item.active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #fbbd08 !important;
  border: 0 !important;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}

.sidebar .item:not(.active):hover {
  border-color: #fbbd08 !important;
  background-color: #dfdfdf !important;
}

#content {
  transition: left .5s ease;
}

.sidebar.visible ~.pusher #content {
  left: 260px;
}
